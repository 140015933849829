


























































































import Vue from "vue";
import { mapState } from "vuex";
import { dispatch, ListQuery, Product, RootState } from "@/store";
import VContact from "@/components/VContact.vue";

export default Vue.extend({
  components: { VContact },
  data() {
    return {
      loading: false,
      isHeaderFixed: false,
      menuVisible: false,
      contactVisible: false,
      topList: [] as Product[],
      notificationCount: 0,
    };
  },
  computed: mapState({
    profile(state: RootState) {
      return state.profile;
    },
    list(state: RootState) {
      return state.products.list;
    },
    query(state: RootState) {
      return state.products.query;
    },
    count(state: RootState) {
      return state.products.count;
    },
  }),
  methods: {
    onScroll({ isFixed }: { isFixed: boolean }) {
      this.isHeaderFixed = isFixed;
    },
    onNotificationClick() {
      if (this.profile.uuid) {
        this.$router.push(this.$paths.notifications);
      } else {
        this.$router.push(this.$paths.authSignIn);
      }
    },
    onAvatarClick() {
      if (this.profile.uuid) {
        this.$router.push(this.$paths.profile);
      } else {
        this.$router.push(this.$paths.authSignIn);
      }
    },
    toOfficial() {
      window.location.href = "https://www.angrymiao.com/";
    },
    onSignOut() {
      dispatch.appSignOut().then(() => {
        window.location.href = this.$paths.authSignIn;
      });
    },
    getList(query: ListQuery) {
      this.loading = true;
      dispatch
        .productsGetList({
          ...query,
          ids: this.$route.query.ids as string,
        })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onRefresh() {
      this.getList({
        ...this.query,
        page_number: 1,
      });
      this.getNotificationUnRead();
    },
    onLoad() {
      this.getList({
        ...this.query,
        page_number: this.query.page_number + 1,
      });
    },
    onProductClick(product: Product) {
      if (product.product.is_hidden) {
        this.$router.push(
          this.$paths.productsHidden + "/" + product.product.hidden_id
        );
      } else {
        this.$router.push(this.$paths.products + "/" + product.product.id);
      }
    },
    getNotificationUnRead() {
      if (this.profile.uuid) {
        dispatch.notificationsGetUnRead().then((res) => {
          this.notificationCount = res.count;
        });
      }
    },
  },
  mounted() {
    dispatch.productsGetTopList().then((res) => {
      this.topList = res;
    });
    this.onRefresh();
  },
});
